import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = ({ selectedSkill }) => {
  const projects = [
    { title: "Portfolio Site", description: "Développement & Design", imgUrl: projImg1, skills: ["Web Development"] },
    { title: "Lua", description: "Lua Design", imgUrl: projImg2, skills: ["Lua"] },
    { title: "Corporate Logo", description: "SQL / Gestion de base de donnée ", imgUrl: projImg3, skills: ["SQL / Gestion de base de donnée "] },
    { title: "E-commerce Site", description: "Site Web", imgUrl: projImg1, skills: ["Web Development"] }
  ];

  const filteredProjects = selectedSkill
    ? projects.filter(project => project.skills.includes(selectedSkill))
    : projects;

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Projets / Formation - {selectedSkill || "Tous"}</h2>
                <p>Découvrez quelques projets que j'ai réalisés, mettant en avant mes compétences en développement et en design.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__pulse" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {filteredProjects.map((project, index) => (
                          <ProjectCard
                            key={index}
                            {...project}
                          />
                        ))}
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background"/>
    </section>
  );
};
