// import meter1 from "../assets/img/meter1.svg";
// import meter2 from "../assets/img/meter2.svg";
// import meter3 from "../assets/img/meter3.svg";

import BDD from "../assets/img/skill/BDD.png";
import lua from "../assets/img/skill/lua.png";
import reseau from "../assets/img/skill/reseau.png";
import HTML from "../assets/img/skill/HTML.png";
import react from "../assets/img/skill/react.png";
import XML from "../assets/img/skill/XML.png";
import python from "../assets/img/skill/python.png";
// import fusion from "../assets/img/skill/fusion.png";
import php from "../assets/img/skill/php.png";
// import office from "../assets/img/skill/office.png";
import api from "../assets/img/skill/api.png";
import admin from "../assets/img/skill/admin.png";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";

export const Skills = ({ selectedSkill, onSelectSkill }) => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  // Fonction pour gérer la sélection et désélection de la compétence
  const handleSkillClick = (skill) => {
    if (selectedSkill === skill) {
      onSelectSkill(null); // Désélectionne si on clique sur la même compétence
    } else {
      onSelectSkill(skill);
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Compétences</h2>
              <p>Mes compétences couvrent divers domaines du développement informatique,<br /> du front-end au back-end.</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">


                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Systèmes réseaux")}
                        className={`skill-button ${selectedSkill === "Systèmes réseaux" ? "active" : ""}`}
                      >
                        <img src={reseau} alt="Systèmes réseaux" />
                        <h5>Systèmes réseaux</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>


                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Lua")}
                        className={`skill-button ${selectedSkill === "Lua" ? "active" : ""}`}
                      >
                        <img src={lua} alt="Lua" />
                        <h5>Lua</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>


                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Gestion de base de donnée")}
                        className={`skill-button ${selectedSkill === "Gestion de base de donnée" ? "active" : ""}`}
                      >
                        <img src={BDD} alt="Gestion de base de donnée" />
                        <h5>Gestion de base de donnée</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("HTML/CSS/JS")}
                        className={`skill-button ${selectedSkill === "HTML/CSS/JS" ? "active" : ""}`}
                      >
                        <img src={HTML} alt="HTML/CSS/JS" />
                        <h5>HTML/CSS/JS</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("React")}
                        className={`skill-button ${selectedSkill === "React" ? "active" : ""}`}
                      >
                        <img src={react} alt="React" />
                        <h5>React</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Python")}
                        className={`skill-button ${selectedSkill === "Python" ? "active" : ""}`}
                      >
                        <img src={python} alt="Python" />
                        <h5>Python</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("PHP")}
                        className={`skill-button ${selectedSkill === "PHP" ? "active" : ""}`}
                      >
                        <img src={php} alt="PHP" />
                        <h5>PHP</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("JSON/XML")}
                        className={`skill-button ${selectedSkill === "JSON/XML" ? "active" : ""}`}
                      >
                        <img src={XML} alt="JSON/XML" />
                        <h5>JSON/XML</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>


                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Utilisation d'API")}
                        className={`skill-button ${selectedSkill === "Utilisation d'API" ? "active" : ""}`}
                      >
                        <img src={api} alt="Utilisation d'API" />
                        <h5>Utilisation d'API</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Administration système")}
                        className={`skill-button ${selectedSkill === "Administration système" ? "active" : ""}`}
                      >
                        <img src={admin} alt="Administration système" />
                        <h5>Administration système</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Hardware")}
                        className={`skill-button ${selectedSkill === "Hardware" ? "active" : ""}`}
                      >
                        <img src={admin} alt="Hardware" />
                        <h5>Hardware</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                <div className="item">
                  <Router>
                    <HashLink to="#projects">
                      <button
                        onClick={() => handleSkillClick("Software")}
                        className={`skill-button ${selectedSkill === "Software" ? "active" : ""}`}
                      >
                        <img src={admin} alt="Software" />
                        <h5>Software</h5>
                      </button>
                    </HashLink>
                  </Router>
                </div>

                
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  );
};
